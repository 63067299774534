import React, { useState } from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import TepeConnectRegistrationModel from './Models/TepeConnectRegistrationModel.interface';
import TepeConnectRegistrationPostModel from './Models/TepeConnectRegistrationPostModel.interface';
import TepeConnectRegistrationResponse from './Models/TepeConnectRegistrationResponse.interface';
import { PostRegistration } from './Register';
import LeftPanel from '../LeftNavigationLayout/LeftPanel';
import ContentDivider from '../Shared/Divider/Divider';
import IconLink from '../Shared/IconLink/IconLink';
import { LinkType } from '../Enums/LinkType.enum';
import { FeedbackButton } from '../Shared/Button/Button';
import KexInput from '../Shared/Input/KexInput';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import { InputType } from '../Enums/InputType.enum';
import KexRadio from '../Shared/KexRadio/KexRadio';
import KexCheckbox from '../Shared/KexCheckbox/KexCheckbox';
import KexLink from '../Shared/KexLink/KexLink';
import Translation, { translate } from '../Shared/Common/Translate';
import { GenericDropDown } from '../Shared/ValueDropDown/GenericDropDown';
import TepeNotification from '../Shared/Common/TepeNotification';

function TepeConnectRegistrationPage() {
  const {
    staticPages: {
      contactPage,
      tepeConnectTermsPage,
      tepeTermsOfUsePage,
      tepeConnectRegistrationPage,
    },
    translations: {
      'common/generalError': generalError,
      'tepeConnect/fields/firstname': firstname,
      'tepeConnect/fields/surname': surname,
      'tepeConnect/fields/email': email,
      'tepeConnect/fields/phoneNumber': phoneNumber,
      'tepeConnect/fields/dentalProfessional': dentalProfessional,
      'tepeConnect/fields/dentalStudent': dentalStudent,
      'tepeConnect/fields/dentalSchool': dentalSchool,
      'tepeConnect/fields/studyCourse': courseOfStudy,
      'tepeConnect/fields/GDCIDC': GDCIDC,
      'tepeConnect/fields/additionalInfo': additionalInfo,
      'tepeConnect/submit': submitText,
      'tepeConnect/validation/invalidPhoneNumber': invalidPhoneNumber,
      'tepeConnect/validation/invalidEmail': invalidEmail,
      'tepeConnect/validation/invalidLength': invalidLength,
      'tepeConnect/terms/text': termsText,
      'tepeConnect/terms/tepeTermsText': tepeTermsText,
      'tepeConnect/terms/tepeConnectTermsText': tepeConnectTermsText,
      'tepeConnect/titleValues/label': titleLabel,
      'tepeConnect/titleValues/prof': profValue,
      'tepeConnect/titleValues/dr': drValue,
      'tepeConnect/titleValues/mr': mrValue,
      'tepeConnect/titleValues/mrs': mrsValue,
      'tepeConnect/titleValues/ms': msValue,
      'tepeConnect/titleValues/miss': missValue,
      'tepeConnect/titleValues/other': otherValue,
      'tepeConnect/roleValues/label': roleLabel,
      'tepeConnect/roleValues/dentist': dentistValue,
      'tepeConnect/roleValues/dentalHygienist': dentalHygienistValue,
      'tepeConnect/roleValues/dentalTherapist': dentalTherapistvalue,
      'tepeConnect/roleValues/dentalNurse': dentalNurseValue,
      'tepeConnect/roleValues/dentalStudent': dentalStudentValue,
      'tepeConnect/roleValues/other': roleOtherValue,
      'common/contact': contactUsLabel,
    },
  } = useAppSettingsData();

  const {
    pageHeading,
    pageInfoText,
    helpInfoHeader,
    helpInfoText,
    registrationHeading,
    registrationIntroText,
  } = useCurrentPage<TepeConnectRegistrationModel>();

  const [dentalProfessionalOption, setDentalProfessionalOption] = useState<
    boolean
  >(false);
  const [dentalStudentOption, setDentalStudentOption] = useState<boolean>(
    false
  );
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [currentTitle, setCurrentTitle] = useState<string>('');
  const [currentRole, setCurrentRole] = useState<string>('');
  const [userFields, setUserFields] = useState(
    {} as TepeConnectRegistrationPostModel
  );

  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');

  const updateUserField = (value: string, name?: string) => {
    if (name) {
      setUserFields((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const getUserFieldsPostdata = () => {
    return {
      ...userFields,
      acceptTerms: termsAgreed,
      ...(dentalStudentOption && {
        dentalStudent: true,
      }),
      ...(dentalProfessionalOption && {
        qualifiedDentalProfessional: true,
        ...(currentRole && {
          currentRole: currentRole,
        }),
      }),
      ...(currentTitle && {
        title: currentTitle,
      }),
    };
  };

  const onSuccess = (data: TepeConnectRegistrationResponse) => {
    setSuccessMessage(data.message);
  };

  const onError = (data?: TepeConnectRegistrationResponse) => {
    data && setErrorMessage(data.errors);
    !data && setErrorMessage([generalError]);
  };

  const postRegistrationForm = async () => {
    setErrorMessage([]);
    setSuccessMessage('');
    const data = getUserFieldsPostdata();
    await PostRegistration(
      tepeConnectRegistrationPage,
      data,
      onSuccess,
      onError
    );
  };

  const setOptionDentalProfessional = () => {
    setDentalStudentOption(false);
    setDentalProfessionalOption(true);
    userFields.dentalSchool = '';
    userFields.courseOfStudy = '';
  };

  const setOptionDentalStudent = () => {
    setDentalStudentOption(true);
    setDentalProfessionalOption(false);
    userFields.gdcidcNumber = '';
    userFields.currentRole = '';
  };

  const validForm =
    formValid &&
    !!currentTitle &&
    termsAgreed &&
    (dentalProfessionalOption ? currentRole : true);

  const ConnectTermsPage = () => {
    return (
      <TermsLink href={tepeConnectTermsPage}>{tepeConnectTermsText}</TermsLink>
    );
  };

  const TermsOfUsePage = () => {
    return <TermsLink href={tepeTermsOfUsePage}>{tepeTermsText}</TermsLink>;
  };

  const LeftComponent = (
    <>
      <Header>{pageHeading}</Header>
      <IntroText>{pageInfoText}</IntroText>
      <Divider />
      <SmallHeader>{helpInfoHeader}</SmallHeader>
      <IntroText>{helpInfoText}</IntroText>
      <StyledIconLink href={contactPage} type={LinkType.Arrow}>
        {contactUsLabel}
      </StyledIconLink>
    </>
  );

  const DentalProfessionalFields = (
    <>
      <GenericDropDown
        values={[
          dentistValue,
          dentalHygienistValue,
          dentalTherapistvalue,
          dentalNurseValue,
          dentalStudentValue,
          roleOtherValue,
        ]}
        currentValue={currentRole}
        setSelectedValue={setCurrentRole}
        ghostLabel={roleLabel}
      />

      <KexInput
        title={GDCIDC + '*'}
        onChange={updateUserField}
        name="gdcidcNumber"
        value={userFields?.gdcidcNumber || ''}
        validation={{
          required: dentalProfessionalOption,
          minLength: 1,
          errorMessage: translate(invalidLength, [1]).join(' '),
        }}
        useDebounce
      />
    </>
  );

  const DentalStudentFields = (
    <>
      <KexInput
        title={dentalSchool + '*'}
        onChange={updateUserField}
        name="dentalSchool"
        value={userFields?.dentalSchool || ''}
        validation={{
          required: true,
          minLength: 1,
          errorMessage: translate(invalidLength, [1]).join(' '),
        }}
        useDebounce
      />

      <KexInput
        title={courseOfStudy + '*'}
        onChange={updateUserField}
        name="courseOfStudy"
        value={userFields?.courseOfStudy || ''}
        validation={{
          required: dentalStudentOption,
          minLength: 1,
          errorMessage: translate(invalidLength, [1]).join(' '),
        }}
        useDebounce
      />
    </>
  );

  return (
    <LeftPanel leftComponent={LeftComponent}>
      <Wrapper>
        <FormContainer>
          <FromTitle>{registrationHeading}</FromTitle>
          <styled.P>{registrationIntroText}</styled.P>
          <FormWrapper>
            <KexInputValidation
              onInputsValid={() => {
                setFormValid(true);
              }}
              onInputsInvalid={() => {
                setFormValid(false);
              }}
            >
              <GenericDropDown
                values={[
                  profValue,
                  drValue,
                  mrValue,
                  mrsValue,
                  msValue,
                  missValue,
                  otherValue,
                ]}
                currentValue={currentTitle}
                setSelectedValue={setCurrentTitle}
                ghostLabel={titleLabel}
              />

              <KexInput
                title={firstname + '*'}
                validation={{
                  required: true,
                  minLength: 3,
                  maxLength: 30,
                  errorMessage: translate(invalidLength, [3]).join(' '),
                }}
                onChange={updateUserField}
                name="firstName"
                useDebounce
                value={userFields?.firstName || ''}
              />

              <KexInput
                title={surname + '*'}
                validation={{
                  required: true,
                  minLength: 1,
                  errorMessage: translate(invalidLength, [1]).join(' '),
                }}
                onChange={updateUserField}
                name="lastName"
                value={userFields?.lastName || ''}
                useDebounce
              />

              <KexInput
                title={email + '*'}
                validation={{
                  required: true,
                  minLength: 1,
                  maxLength: 40,
                  pattern: /(?=^.{1,241}$)^([\w.-]+)@([\w-]+)((\.(\w){2,})+)$/,
                  errorMessage: !userFields?.email
                    ? translate(invalidLength, [1]).join(' ')
                    : invalidEmail,
                }}
                useDebounce
                onChange={updateUserField}
                name="email"
                value={userFields?.email || ''}
              />

              <KexInput
                title={phoneNumber + '*'}
                validation={{
                  required: true,
                  pattern: /(^[\\s\-+()0-9]{1,16}$)/,
                  errorMessage: invalidPhoneNumber,
                }}
                onChange={updateUserField}
                name="phoneNumber"
                value={userFields?.phoneNumber || ''}
                useDebounce
              />

              <ChoicesWrapper>
                <KexRadio
                  onChange={setOptionDentalProfessional}
                  isChecked={dentalProfessionalOption}
                  label={dentalProfessional}
                />
                <KexRadio
                  onChange={setOptionDentalStudent}
                  isChecked={dentalStudentOption}
                  label={dentalStudent}
                />
              </ChoicesWrapper>

              {dentalStudentOption && DentalStudentFields}
              {dentalProfessionalOption && DentalProfessionalFields}
            </KexInputValidation>
            {(dentalStudentOption || dentalProfessionalOption) && (
              <KexInputValidation>
                <KexInput
                  inputType={InputType.TextArea}
                  title={additionalInfo}
                  name="whereDidYouHearAboutUs"
                  onChange={updateUserField}
                />

                <TermsWrapper>
                  <KexCheckbox
                    onChange={setTermsAgreed}
                    isChecked={termsAgreed}
                    label={
                      <Translation
                        translation={termsText}
                        values={[<TermsOfUsePage />, <ConnectTermsPage />]}
                      />
                    }
                  />
                </TermsWrapper>
              </KexInputValidation>
            )}
          </FormWrapper>
          <ButtonContainer>
            <ForwardButton disabled={!validForm} action={postRegistrationForm}>
              {submitText}
            </ForwardButton>
          </ButtonContainer>
          {!!errorMessage?.length &&
            errorMessage.map((msg, _i) => (
              <TepeNotification key={_i} isError={true} text={msg} />
            ))}

          {!!successMessage && (
            <TepeNotification isError={false} text={successMessage} />
          )}
        </FormContainer>
      </Wrapper>
    </LeftPanel>
  );
}

export default TepeConnectRegistrationPage;

const Header = styled.h1({
  font: { size: theme.eta, weight: theme.fontWeight.normal },
  color: theme.black,
  margin: { bottom: theme.spacing(4) },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.moreWide,
});

const IntroText = styled.p({
  font: { size: theme.gamma },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.nearMedium,
  color: theme.black,
});

const Divider = styled(ContentDivider, {
  width: '100%',
  backgroundColor: theme.black,
  margin: { y: theme.spacing(8) },
});

const SmallHeader = styled.h3({
  font: { size: theme.epsilon, weight: theme.fontWeight.normal },
  color: theme.black,
  margin: { bottom: theme.spacing(4) },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.moreWide,
});

const StyledIconLink = styled(IconLink, {
  marginTop: theme.spacing(4),
  marginLeft: 'auto',
});

const Wrapper = styled.div({
  padding: { x: 0, y: theme.spacing(8) },
});

const FormContainer = styled.div({
  maxWidth: 400,
  width: '100%',
  margin: { x: 'auto' },

  ...media(theme.mediaQuery.mediaMaxMedium, {
    padding: { x: theme.spacing(4) },
    maxWidth: '100%',
  }),
});

const FromTitle = styled.h3({
  font: { size: theme.epsilon, weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(1),
});

const FormWrapper = styled.div({
  marginTop: theme.spacing(5),
});

const ButtonContainer = styled.div({
  margin: { x: 'auto', y: theme.spacing(4) },
});

const ForwardButton = styled(FeedbackButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  marginLeft: 'auto',
  letterSpacing: theme.letterSpacing.medium,
  padding: { x: theme.spacing(8), y: theme.spacing(3) },
  ...media(theme.mediaQuery.mediaMaxMedium, {
    width: '100%',
  }),
});

const ChoicesWrapper = styled.div({
  margin: { y: theme.spacing(8) },
});

const TermsWrapper = styled.div({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(12),
});

const TermsLink = styled(KexLink, {
  textDecoration: 'underline',
});
