import TepeConnectRegistrationPostModel from './Models/TepeConnectRegistrationPostModel.interface';
import TepeConnectRegistrationResponse from './Models/TepeConnectRegistrationResponse.interface';

export async function PostRegistration(
  url: string,
  data: TepeConnectRegistrationPostModel,
  onSuccess: (data: TepeConnectRegistrationResponse) => void,
  onError: (data?: TepeConnectRegistrationResponse) => void
) {
  try {
    const res = await fetch(`${url}/CreateTepeConnectUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify(data),
    });

    const jsonRes = (await res.json()) as TepeConnectRegistrationResponse;

    if (jsonRes.statusCode !== 200) {
      onError(jsonRes);
      return;
    }

    onSuccess(jsonRes);
  } catch (e) {
    onError();
  }
}
